import React, { PureComponent, Fragment } from "react";
import IconEmailSend from '@cimpress-technology/react-streamline-icons/lib/IconEmailSend';

const iconStyle = { padding: "0 17px" };
const linkStyle = { display: "flex", alignItems: "center" };

export default class Contact extends PureComponent {
  render() {
    return (
      <Fragment>
        <h1 className="text-center section-header">Contact Us</h1>
        <div className="card-deck">
          <div className="card card-minimal">
            <div className="card-header">
              <h5>Contact</h5>
            </div>
            <div className="card-block">
              <div>
                <a href="https://cimpress.slack.com/archives/C5THQR96X" target="_blank" rel="noopener noreferrer">
                  <img src="images/Slack_Mark.svg" alt="slack logo" style={{ height: "60px" }} />
                  #ux-support
                </a>
              </div>
              <div>
                <a href="mailto:PlaformUX@cimpress.com" style={linkStyle}>
                  <span style={iconStyle}>
                    <IconEmailSend size="2x" weight="fill" />
                  </span>
                  PlaformUX@cimpress.com
                </a>
              </div>
            </div>
          </div>
          <div className="card card-minimal">
            <div className="card-header">
              <h5>Component Library</h5>
            </div>
            <div className="card-block">
              <div>
                <a href="https://cimpress.slack.com/messages/C5SD7ME0G" target="_blank" rel="noopener noreferrer">
                  <img src="images/Slack_Mark.svg" alt="slack logo" style={{ height: "60px" }} />
                  #component-library
                </a>
              </div>
              <div>
                <a href="mailto:ComponentLibrary@cimpress.com" style={linkStyle}>
                  <span style={iconStyle}>
                    <IconEmailSend size="2x" weight="fill" />
                  </span>
                  ComponentLibrary@cimpress.com
                </a>
              </div>
            </div>
          </div>
          {/* <div className="card card-minimal">
          <div className="card-header">
              <h5>Office Hours</h5>
            </div>
            <div className="card-block">
              <div>Wednesdays</div>
              <div>11 AM EST</div>
              <div>WLT 3163 Productization</div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px'}}>
                <a href="" className="btn btn-default">Sign up for a Slot</a>
                <a href="" className="btn btn-default">Attend with Zoom</a>
              </div>
            </div>
          </div> */}
        </div>
      </Fragment>
    );
  }
}
