import React, { PureComponent } from 'react';

export default class Intro extends PureComponent {
  render() {
    return (
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <img
          src="images/ux-logo-diamond-persimmon.png"
          alt="ux logo"
          style={{ height: '145px', marginLeft: '16px', marginRight: '25px' }}
        />
        <div style={{ maxWidth: '75vw' }}>
          <h1>Your resource for everything UX</h1>
          <p className="marketing">
            The Cimpress Platform UX team works with tribes and squads across the organization to create a seamless user
            experience for our customers. We strive to have a deep understanding on our customers through user research,
            design usable and useful experiences, and have a consistent look and feel across the platform.
          </p>
        </div>
      </div>
    );
  }
}
