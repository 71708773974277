import {centralizedAuth} from '@cimpress/simple-auth-wrapper';

export const CLIENT_ID = 'dcVKKKxgEdDRXJL3R2kWTTD82gUePzCl';  // mex spas3 clientID.  must be replaced in your application

const auth = new centralizedAuth({
  clientID: CLIENT_ID,
  redirectRoute: '/'
});

export default auth;
