import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { GlobalStyles } from "@cimpress/react-components";
import createHistory from "history/createBrowserHistory";
import Shell from "./Shell.jsx";
import HomePage from "./components/HomePage.jsx";
import ComponentDocs from "./components/ComponentDocs.jsx";
import PageNotFound from "./components/404.jsx";
import auth from "./auth";
import Loading from "./components/Loading.jsx";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    const basename = process.env.REACT_APP_ROUTER_BASENAME || "";
    this.history = createHistory({ basename });
  }

  componentWillMount() {
    if (!auth.isLoggedIn()) {
      this.setState({ authorizing: true });
      auth
        .ensureAuthentication(window.location.pathname + window.location.search)
        .then(authorized => {
          this.setState({ authorizing: false });
        })
        .catch(err => {
          this.setState({ authorizationError: err });
        });
    }
  }

  render() {
    const { authorizing, authorizationError } = this.state;
    return (
      <div>
        {authorizing ? (
          <Loading />
        ) : auth.isLoggedIn() ? (
          <BrowserRouter>
            <GlobalStyles />
            <Shell>
              <Switch>
                <Route exact path="/(index.html)?" component={HomePage} />
                <Route path="/components" component={ComponentDocs} />
                <Route component={PageNotFound} />
              </Switch>
            </Shell>
          </BrowserRouter>
        ) : authorizationError ? (
          <div>Unexpected error encountered. {authorizationError.message}</div>
        ) : null}
      </div>
    );
  }
}

export default App;
