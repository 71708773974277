import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

export default class ComponentsCallout extends PureComponent {
  render() {
    return (
      <div
        style={{
          height: "500px",
          backgroundImage: "url('/images/CompiledwConfetti.png')",
          backgroundSize: "cover",
          width: "100%",
          paddingTop: "80px"
        }}
      >
        <div style={{ width: "30%", padding: "20px", marginLeft: "20px", backgroundColor: "white" }}>
          <h3>We've Got Components!</h3>
          <p>
            We've organized the <b>component library</b> and <b>shared components</b> in one place! Find documentation
            on how to use them, as well as the code you need.
          </p>
          <Link className="btn btn-primary" to="/components">
            Go to Components
          </Link>
          <h5>Have a new component? Submit it to the library.</h5>
          <p>The more you contribute, the more you can level up with our robot swag.</p>
        </div>
      </div>
    );
  }
}
