import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '@cimpress-technology/react-platform-header';
import auth from './auth'
import Package from '../package.json';

const headerNav = [
  {
    id: 'home',
    content: <Link to="/">Home</Link>
  },
  {
    id: 'components',
    content: <Link to="/components">Components</Link>
  },
]

export default class App extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
  }

  render() {
    let envString = `v.${Package.version}`;
    if(process.env.REACT_APP_ENVIRONMENT_NAME) {
      envString += ` - ${process.env.REACT_APP_ENVIRONMENT_NAME}`;
    }

    if(process.env.REACT_APP_GIT_COMMIT) {
      envString += ` (${process.env.REACT_APP_GIT_COMMIT})`;
    }

    return (
      <div>
        <div className="appShell">
          <Header
            appTitle={<Link to="/">Platform UX</Link>}
            appLinks={headerNav}
            profile={auth.getProfile()}
            onLogInClicked={() => auth.login()}
            onLogOutClicked={() => auth.logout('/')}
            isLoggedIn={auth.isLoggedIn()}
            accessToken={auth.getAccessToken()}
          />
            {this.props.children}
        </div>
        <div className="hidden">{envString}</div>
      </div>
    );
  }
}
