import React, { Component } from "react";
import Intro from "./home/Intro";
import ComponentsCallout from "./home/ComponentsCallout";
import Contact from "./home/Contact";

const paddingStyle = { paddingTop: "30px", paddingBottom: "30px" };
export default class HomePage extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12" style={{ paddingTop: "30px", paddingBottom: "10px" }}>
            <Intro />
          </div>
          <div className="col-md-12" style={{ paddingRight: 0, paddingLeft: 0, ...paddingStyle }}>
            <ComponentsCallout />
          </div>
          <div className="col-md-12" style={paddingStyle}>
            <Contact />
          </div>
        </div>
      </div>
    );
  }
}
