import React, { Component } from "react";
import { Card, TextField } from "@cimpress/react-components";
import sortBy from "lodash/sortBy";
import componentList from "../content/components.js";

export default class ComponentDocs extends Component {
  state = { searchString: "" };
  onChangeSearch = e => this.setState({ searchString: e.target.value });
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>Component Library</h1>
            <TextField value={this.state.searchString} onChange={this.onChangeSearch} placeholder="Filter components" />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridGap: "30px"
              }}
            >
              {sortBy(componentList, "name")
                .filter(
                  componentData =>
                    componentData.name &&
                    componentData.name.toLowerCase().includes(this.state.searchString.toLowerCase())
                )
                .map(componentData => (
                  <Card
                    key={componentData.name}
                    isMinimal
                    header={
                      <div>
                        <b>{componentData.name}</b> <div className="h7">{componentData.team}</div>
                      </div>
                    }
                  >
                    <p>{componentData.description}</p>
                    <div>
                      {componentData.demo && (
                        <a href={componentData.demo} target="_blank" rel="noopener noreferrer" className="btn btn-default" style={{ marginRight: "15px" }}>
                          Demo
                        </a>
                      )}
                      {componentData.repo && (
                        <a href={componentData.repo} target="_blank" rel="noopener noreferrer" className="btn btn-default">
                          Code
                        </a>
                      )}
                    </div>
                  </Card>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
