const componentList = [
  {
    name: "@Cimpress UI Library",
    packageName: "@cimpress/react-components",
    description: "A library of UI components for designing Cimpress-branded applications",
    team: "",
    demo: "https://static.ux.cimpress.io/react-components/latest",
    repo: "https://gitlab.com/Cimpress-Technology/internal-open-source/component-library/react-components"
  },
  {
    name: "Preview Carousel",
    packageName: "@cimpress-technology/mex-preview-carousel",
    description: "Use a SKU, variables, and/or Document Reference URL to display a carousel of preview images",
    team: "MEX",
    demo: "https://demos.mex.cimpress.io/react-platform-components/preview-carousel",
    repo: "https://gitlab.com/Cimpress-Technology/orders/mex/react-platform-components/tree/master/packages/mex-preview-carousel"
  },
  {
    name: "Header",
    packageName: "@cimpress-technology/react-platform-header",
    description: "Cimpress-branded header for platform applications",
    team: "MEX",
    demo: "https://demos.mex.cimpress.io/react-platform-components/header",
    repo:
      "https://gitlab.com/Cimpress-Technology/orders/mex/react-platform-components/tree/master/packages/react-platform-header"
  },
  {
    name: "Settings",
    packageName: "@cimpress-technology/react-platform-settings",
    description: "Manage global and application-specific settings.",
    team: "MEX",
    demo: "https://demos.mex.cimpress.io/react-platform-components/settings",
    repo:
      "https://gitlab.com/Cimpress-Technology/orders/mex/react-platform-components/tree/master/packages/react-platform-settings"
  },
  {
    name: "Tenant Selector",
    packageName: "@cimpress-technology/react-platform-tenant-selector",
    description: "Select a merchant or fulfiller to act on the behalf of",
    team: "MEX",
    demo: "https://demos.mex.cimpress.io/react-platform-components/tenant-selector",
    repo:
      "https://gitlab.com/Cimpress-Technology/orders/mex/react-platform-components/tree/master/packages/react-platform-tenant-selector"
  },
  {
    name: "Streamline Icons",
    packageName: "@cimpress-technology/react-streamline-icons",
    description: "Individually packed svg icons in three weights",
    team: "MEX",
    demo: "https://demos.mex.cimpress.io/react-platform-components/icons",
    repo:
      "https://gitlab.com/Cimpress-Technology/orders/mex/react-platform-components/tree/master/packages/react-streamline-icons"
  },
  {
    name: "Notifications Hub",
    packageName: "@cimpress-technology/react-notifications-hub",
    description: "Collection of components to help with integrating with B2B's notifications hub.",
    team: "MEX",
    demo: "https://demos.mex.cimpress.io/react-platform-components/notification-hub",
    repo:
      "https://gitlab.com/Cimpress-Technology/orders/mex/react-platform-components/tree/master/packages/react-notifications-hub"
  },
  {
    name: "Platform Uploader",
    packageName: "@cimpress-technology/react-platform-uploader",
    description: "Drag-and drop upload component built on react-dropzone.",
    team: "MEX",
    demo: "https://demos.mex.cimpress.io/react-platform-components/uploader",
    repo:
      "https://gitlab.com/Cimpress-Technology/orders/mex/react-platform-components/tree/master/packages/react-platform-uploader"
  },
  {
    name: "Datasheet Editor",
    packageName: "@cimpress-technology/datasheet-editor",
    description: "",
    team: "Pricing",
    demo: "https://components.ff.cimpress.io/datasheet-editor",
    repo: "https://gitlab.com/Cimpress-Technology/ff/packages/react-ff/tree/master/packages/datasheet-editor"
  },
  {
    name: "Formula Editor",
    packageName: "@cimpress-technology/formula-editor",
    description: "",
    team: "Pricing",
    demo: "https://components.ff.cimpress.io/formula-editor",
    repo: "https://gitlab.com/Cimpress-Technology/ff/packages/react-ff/tree/master/packages/formula-editor"
  },
  {
    name: "Input Selector",
    packageName: "@cimpress-technology/input-selector",
    description: "",
    team: "Pricing",
    demo: "https://components.ff.cimpress.io/input-selector",
    repo: "https://gitlab.com/Cimpress-Technology/ff/packages/react-ff/tree/master/packages/input-selector"
  },
  {
    name: "Price Variable",
    packageName: "@cimpress-technology/price-variable",
    description: "",
    team: "Pricing",
    demo: "https://components.ff.cimpress.io/price-variable",
    repo: "https://gitlab.com/Cimpress-Technology/ff/packages/react-ff/tree/master/packages/price-variable"
  },
  {
    name: "Pricing Grid",
    packageName: "@cimpress-technology/pricing-grid",
    description: "",
    team: "Pricing",
    demo: "https://components.ff.cimpress.io/pricing-grid",
    repo: "https://gitlab.com/Cimpress-Technology/ff/packages/react-ff/tree/master/packages/pricing-grid"
  },
  {
    name: "XLSX Loader",
    packageName: "",
    description: "",
    team: "Pricing",
    demo: "https://components.ff.cimpress.io/xlsx-loader",
    repo: "https://gitlab.com/Cimpress-Technology/ff/packages/react-ff/tree/master/packages/xlsx-loader"
  },
  {
    name: "Price Attribute Aggregator",
    packageName: "@cimpress-technology/price-attribute-aggregator",
    description: "",
    team: "Ravenclaw",
    demo: "https://components.ff.cimpress.io/price-attribute-aggregator",
    repo: ""
  },
  {
    name: "Loudspeaker",
    packageName: "@cimpress-technology/react-loudspeaker",
    description: "Loudspeaker and LoudspeakerIcon components provide quick and easy access to the Loudspeaker service.",
    team: "Trdelnik",
    demo: "https://cimpress-support.atlassian.net/wiki/spaces/COM/pages/1604879664/react-loudspeaker",
    repo: "https://gitlab.com/Cimpress-Technology/internal-open-source/trdelnik/react-loudspeaker"
  },
  {
    name: "Baywatch",
    packageName: "@cimpress-technology/react-baywatch",
    description: "Let your users watch resource changes",
    team: "Trdelnik",
    demo: "",
    repo: "https://gitlab.com/Cimpress-Technology/internal-open-source/trdelnik/react-baywatch"
  },
  {
    name: "Templates",
    packageName: "@cimpress-technology/react-cimpress-templates",
    description: "Integrate templates (Email Designer and Stereotype) within your app",
    team: "Trdelnik",
    demo: "",
    repo: "https://gitlab.com/Cimpress-Technology/internal-open-source/trdelnik/react-cimpress-templates"
  },
  {
    name: "Users",
    packageName: "@cimpress-technology/react-cimpress-users",
    description: "Integrate COAM user management within your app",
    team: "Trdelnik",
    demo: "",
    repo: "https://gitlab.com/Cimpress-Technology/internal-open-source/trdelnik/react-cimpress-users"
  },
  {
    name: "Fulfiller Email",
    packageName: "@cimpress-technology/react-email-configuration",
    description: "Fulfiller Email Configuration within your app",
    team: "Trdelnik",
    demo: "",
    repo: "https://gitlab.com/Cimpress-Technology/internal-open-source/trdelnik/react-email-configuration"
  },
  {
    name: "Fulfiller Logo",
    packageName: "@cimpress-technology/react-fulfiller-logo",
    description: "Fetches fulfiller logo from Fulfiller Identity Service.",
    team: "Trdelnik",
    demo: "",
    repo: "https://gitlab.com/Cimpress-Technology/internal-open-source/trdelnik/react-fulfiller-logo"
  },
  {
    name: "Tagliatelle",
    packageName: "@cimpress-technology/react-cimpress-tagliatelle",
    description: "Tag resources with custom data in the UI and manage them.",
    team: "Trdelnik",
    demo: "",
    repo: "https://gitlab.com/Cimpress-Technology/internal-open-source/trdelnik/react-cimpress-tagliatelle"
  },
  {
    name: "FullStory",
    packageName: "@cimpress-technology/react-reporting-redux",
    description: "FullStory and Sentry components to allow easy embedding of these services in your UI",
    team: "Trdelnik",
    demo: "",
    repo: "https://gitlab.com/Cimpress-Technology/internal-open-source/trdelnik/react-reporting-redux"
  },
  {
    name: "Artwork Change",
    packageName: "@cimpress-technology/react-artwork-change",
    description: "A simple component to allow creation of Artwork Change Request on the platform",
    team: "Trdelnik",
    demo: "",
    repo: "https://gitlab.com/Cimpress-Technology/internal-open-source/trdelnik/react-artwork-change"
  },

  {
    name: "FilterBar",
    packageName: "@cimpress-technology/react-filterbar",
    description: "A simple component to allow creation of Artwork Change Request on the platform",
    team: "Lovecraft",
    demo: "https://cimpress-technology.gitlab.io/ff/website-pricing/packages/react-filterbar",
    repo: "https://gitlab.com/Cimpress-Technology/ff/website-pricing/packages/react-filterbar"
  },

  // For new components, fill out the folloowing form
  // {
  //   name: "",
  //   packageName: "",
  //   description: "",
  //   team: "",
  //   demo: "",
  //   repo: ""
  // }
];

export default componentList;
